/**
 * クリア処理
 */
window.clearInput = function () {
    // エラーメッセージクリア
    $('.invalid-feedback').remove();
    // エラー枠クリア
    $('.is-invalid').removeClass('is-invalid');

    //select
    $('.clear-select').prop('selectedIndex', 0).change();
    //value
    $('.clear-value').val('');
    //check
    $('.clear-check').removeAttr('checked').prop('checked', false);
    //date
    $('.clear-date-start').val(getBeginningMonth());
    $('.clear-date-end').val(getEndMonth());

    //得意先
    $('.input-customer').prop('selectedIndex', 0).change();
    changeCustomer();
    //仕入先
    $('.input-supplier').prop('selectedIndex', 0).change();
    changeSupplier();
    //商品
    changeProduct();

    let now = new Date();
    let yearMonth = now.getFullYear() + '-' + ('00' + (now.getMonth() + 1)).slice(-2);
    $('#purchase_date').val(yearMonth);
    $('#charge_date').val(yearMonth);
    $('#closing_date').val(0);
    let today = now.getFullYear() + '-' + ('00' + (now.getMonth() + 1)).slice(-2) + '-' + ('00' + now.getDay()).slice(-2);
    $('#issue_date').val(today);

    $('.input-code-start').val('');
    $('.input-code-end').val('');
    $('.input-name').val('');
    $('.input-name-kana').val('');
    $('.input-customer-code-start').val('');
    $('.input-customer-code-end').val('');
    $('.input-customer-name').val('');
    $('.input-customer-name-kana').val('');
    $('.input-branch-name').val('');
    $('.input-recipient-name').val('');
    $('.input-login-id').val('');

    // 再描画
    // $('#searchForm').submit();
}
